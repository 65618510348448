import { useState } from "react";
import { useForm } from "react-hook-form";

function Login(){
    const {register, handleSubmit} = useForm();

    const onSubmit = async(d) => {
        let url = "https://www.hospital.isaerpweb.com/usuario/login?email=" + d.email + "&pwd=" + d.pwd;
        url = "/usuario/login?email=" + d.email + "&pwd=" + d.pwd;
        let response = await fetch(url);
        let objeto = await response.json();
        if(objeto.Id == 0)
           alert("Datos incorrectos");
        else 
           alert("Bienvenido " + objeto.Nombre);
    }
    return (
        <div className="card" style={{ width: "18rem" }}>
            <div className="card-header">
                <h6>Ingrese sus datos</h6>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                <table>
                    <tbody>
                    <tr>
                        <td>Email</td>
                        <td>
                            <input className="form-control form-control-sm"
                                {...register("email")}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Contraseña</td>
                        <td><input className="form-control form-control-sm"
                            {...register("pwd")}
                            type="password"
                        />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                        <input type="submit" value="Iniciar sesion" className="btn btn-outline-success btn-sm"/>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </form>
            </div>
        </div>
    )
}

export default Login;