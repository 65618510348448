import MenuPrincipal from "./MenuPrincipal";
import Login from "./Login";
import { useState } from "react";

function App() {
  let [logueado, setLogueado] = useState(false);

  return (
    <div>
      { !logueado && <Login /> }
      { logueado && <MenuPrincipal /> }
    </div>
  );
}

export default App;
